body {
  overflow-x: hidden;

  &.onboarding {
    background-color: #ffffff;
  }

  &.dialogue-open {
    overflow: hidden;
  }
}

i {
  font-style: italic;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

sub {
  vertical-align: sub;
}

sub, sup {
  font-size: smaller;
}

sup {
  vertical-align: super;
}

.mce-content-body {
  img, ol, p, table, ul {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.mce-content-body, .read-only {
  border: 0px !important;
  outline: none !important;

  img, ol, p, table, ul {
    [data-testid="simple-choice"] & {
      margin-bottom: 0;
    }
  }

  ol {
    list-style-type: decimal;
  }

  ol, ul {
    list-style-position: inside;
  }

  p {
    display: flex;

    * {
      display: contents;
    }

    img, input {
      display: initial;
      margin-bottom: 0;
    }

    span {
      &[style*="float"] {
        display: initial;
      }
    }
  }

  table {
    border: 0 none !important;
    border-collapse: initial !important;
    width: 100%;

    td {
      text-align: center;
    }

    td, th {
      border: 0 none !important;
      border-top: 1px solid #bbbbbb !important;
      border-left: 1px solid #bbbbbb !important;
      padding: 10px;

      &:last-child {
        border-right: 1px solid #bbbbbb !important;
      }
    }

    tr {
      &:last-child {
        td, th {
          border-bottom: 1px solid #bbbbbb !important;
        }
      }
    }
  }

  ul {
    list-style-type: disc;
  }

  .non-editable {
    margin-left: 10px;
    pointer-events: none;
  }
}

.mce-edit-focus {
  border: 0px !important;
  outline: none !important;
}

.read-only {
  img, ol, p, table, ul {
    margin-bottom: 20px;
  }

  img, ol, p, span, table, ul {
    font-size: 16px;
  }
}
